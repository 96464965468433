<template>
  <d-diseases :role="role" />
</template>

<script>
export default {
  name: 'Diseases',
  page: {
    title: 'CRM Doctis - Болезни',
  },
  computed: {
    role() {
      return this.$store.state.Auth.role;
    },
  },
};
</script>
